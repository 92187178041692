
const useCountries = () => {
    return [
        //{ code: "ar", name: "Argentina" },
        //{ code: "br", name: "Brazil", flag: "🇧🇷" },
        //{ code: "bo", name: "Bolivia", flag: "🇧🇴" }, //1 //no hay tarjeta
        { code: "cl", name: "Chile", flag: "🇨🇱" }, //6
        { code: "co", name: "Colombia", flag: "🇨🇴" }, //2
        //{ code: "ec", name: "Ecuador", flag: "🇪🇨" }, //4 //no hay tarjeta
        { code: "mx", name: "México", flag: "🇲🇽" }, //8
        { code: "pe", name: "Perú", flag: "🇵🇪" },  //5
        { code: "uy", name: "Uruguay", flag: "🇺🇾" }, //7
    ];
}

const docTypes = {
    ar: [
        { code: "ar_cdi", name: "CDI", placeholder: "99-99999999-9" },
        { code: "ar_cuil", name: "CUIL", placeholder: "99-99999999-9" },
        { code: "ar_cuit", name: "CUIT", placeholder: "99-99999999-9" },
        { code: "ar_dni", name: "DNI", placeholder: "99.999.999" },
    ],
    br: [
        { code: "br_cpf", name: "CPF", placeholder: "999.999.999-99" },
        { code: "br_cnpj", name: "CNPJ", placeholder: "00.000.000/0001-00" },
    ],
    cl: [
        { code: "cl_rut", name: "RUT", placeholder: "99.999.999-9" },
    ],
    co: [
        { code: "co_cc", name: "CC", placeholder: "99.999.999" },
        { code: "co_ce", name: "CE", placeholder: "999999" },
        { code: "co_nit", name: "NIT", placeholder: "99.999.999-9" },
    ],
    ec: [
        { code: "ec_doc", name: "DOC", placeholder: "9.999.999-9" },
    ],
    pe: [
        { code: "pe_doc", name: "DOC", placeholder: "99999999-9" },
    ],
    uy: [
        { code: "uy_ci", name: "CI", placeholder: "9.999.999-9" },
    ],
    mx: [
        { code: "mx_curp", name: "CURP", placeholder: "AAAA999999AAAAAA99" },
    ]
};

const useDocTypes = (country) => {
    return docTypes[country] || [];
}

const states = {
    'co': [
        { code: 'Capital District', name: 'Capital District' },
        { code: 'Amazonas', name: 'Amazonas' },
        { code: 'Antioquia', name: 'Antioquia' },
        { code: 'Arauca', name: 'Arauca' },
        { code: 'Atlántico', name: 'Atlántico' },
        { code: 'Bolívar', name: 'Bolívar' },
        { code: 'Boyacá', name: 'Boyacá' },
        { code: 'Caldas', name: 'Caldas' },
        { code: 'Caquetá', name: 'Caquetá' },
        { code: 'Casanare', name: 'Casanare' },
        { code: 'Cauca', name: 'Cauca' },
        { code: 'Cesar', name: 'Cesar' },
        { code: 'Chocó', name: 'Chocó' },
        { code: 'Córdoba', name: 'Córdoba' },
        { code: 'Cundinamarca', name: 'Cundinamarca' },
        { code: 'Guainía', name: 'Guainía' },
        { code: 'Guaviare', name: 'Guaviare' },
        { code: 'Huila', name: 'Huila' },
        { code: 'La Guajira', name: 'La Guajira' },
        { code: 'Magdalena', name: 'Magdalena' },
        { code: 'Meta', name: 'Meta' },
        { code: 'Nariño', name: 'Nariño' },
        { code: 'Norte de Santander', name: 'Norte de Santander' },
        { code: 'Putumayo', name: 'Putumayo' },
        { code: 'Quindío', name: 'Quindío' },
        { code: 'Risaralda', name: 'Risaralda' },
        { code: 'San Andrés y Providencia', name: 'San Andrés y Providencia' },
        { code: 'Santander', name: 'Santander' },
        { code: 'Sucre', name: 'Sucre' },
        { code: 'Tolima', name: 'Tolima' },
        { code: 'Valle del Cauca', name: 'Valle del Cauca' },
        { code: 'Vaupés', name: 'Vaupés' },
        { code: 'Vichada', name: 'Vichada' },
    ]
}

const useStates = (country) => {
    return states[country] || [];
}


export { useCountries, useDocTypes, useStates }