import {
    required,
    alpha,
    // numeric,
    email,
    minValue,
    helpers,
} from "@vuelidate/validators";
import validateCard from "card-validator";

const requiredEs = helpers.withMessage(
    () => `Este campo es requerido`,
    required
);

const alphaEs = helpers.withMessage(() => `Sólo se permiten letras`, alpha);

const emailEs = helpers.withMessage(() => `Ingresa un email válido`, email);

const minValueEs = (min) =>
    helpers.withMessage(
        ({ $params }) => `El monto mínimo es de ${$params.min} USD `,
        minValue(min)
    );

const validateCardNumber = helpers.withMessage(() => `El número de tarjeta es inválido`, (value) => {
    const cleanedNumber = value.replaceAll(/[^\d]/g, "");
    const validation = validateCard.number(cleanedNumber);
    return validation.isValid;
});

const validateCardExpiration = helpers.withMessage(() => `La fecha indicada es inválida`, (value) => {
    const validation = validateCard.expirationDate(value);
    return validation.isValid;
});

export {
    requiredEs as required,
    alphaEs as alpha,
    minValueEs as minValue,
    emailEs as email,
    validateCardNumber,
    validateCardExpiration
}
