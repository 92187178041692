import { db, firebase } from "@/firebase";
import { format, addDays, endOfMonth } from "date-fns";

export default {

    emptyCustomer() {
        return {
            id: '',
            createdAt: '',
            appName: '',
            country: '',
            company: '',
            notes: '',

            plan: {     // se cobra siempre el dia 1 de cada mes
                description: '',
                currency: 'USD',       //siempre USD
                amount: 0,
                startupAmount: 0,
                status: 'pending',    //active, pending, paused, failed
                pausedUntil: format(addDays(endOfMonth(new Date()), 1), "yyyy-MM-dd"),
            },
            contact: {             // lo completa el cliente en SubscribeView
                name: '',
                email: '',
                phone: '',
                docType: '',
                docNumber: '',
            },
            address: {
                state: '',
                city: '',
                street: '',
                streetNumber: '',
                zipcode: '',
            },
            card: {
                holder: '',
                token: null,
                type: '',
                lastFour: '',
                updatedAt: '',
                dueDate: '',
            },
            security: {
                deviceId: '',
                ipAddress: ''
            }
        }
    },

    async listCustomers() {
        const customers = [];

        const querySnapshot = await db.collection("customers").get();

        querySnapshot.forEach(function (doc) {
            customers.push({ ...doc.data(), id: doc.id });
        });
        //console.log(customers)

        return customers;
    },

    async loadCustomer(id) {
        let customer;

        const docRef = await db.collection("customers").doc(id).get();

        if (docRef.exists) {
            //console.log("Document data:", doc.data());
            customer = JSON.parse(JSON.stringify({ ...docRef.data(), id: docRef.id }));

        } else {
            // doc.data() will be undefined in this case
            //console.log("No such document!");
            customer = {}
        }


        return customer;
    },

    async listCustomerTransactions(id) {
        const transactions = [];

        const querySnapshot = await db.collection("customers").doc(id).collection("transactions").orderBy('timestamp', 'desc').get();

        querySnapshot.forEach(function (doc) {
            transactions.push({ ...doc.data() });
        });
        // console.log('transactions ' + transactions)

        return transactions;
    },

    async saveCustomer(customer) {
        // console.log(customer)

        if (customer.plan?.pausedUntil) {
            customer.plan.pausedUntilTS = firebase.firestore.Timestamp.fromDate(new Date(customer.plan.pausedUntil));
        }

        if (customer.id) {
            await db.collection("customers").doc(customer.id).set(customer);

        } else {
            const doc = await db.collection("customers").add(customer);

            if (doc.id) {
                customer.id = doc.id;
            }
        }

        return customer;
    },


}